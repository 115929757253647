import { useCallback, useEffect, useState } from "react";
import styles from "./Login.module.css";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ApiClient from "../../helpers/ApiClient";
import TpaSelectModal from "./TpaSelectModal";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useToggle } from "../../utils/hooks";
import { useSelector, useDispatch, connect } from "react-redux";
import {
	toggleModal,
	toggleUnlockReload,
	toggleUserLoggingOut,
} from "../../redux/modules/timeout";
import SessionTimeoutModal from "../SessionTimeout/SessionTimeoutModal";
import {
	BranchUtil,
	checkSimultaneousLoginScope,
	getScopes,
	TerminalUtil,
	UserInfoUtil,
} from "../../utils/checkSession";
import { useHistory } from "react-router";
import TpaTimerModal from "./TpaTimerModal";
import AccessDeniedDialog from "../Dialog/Dialog";
import { useQuery } from "@apollo/client";
import { GET_LOGIN_REQUEST_USER } from "../../graphql/queries";
import {
	showSessionExpiry,
	refreshTokenBeforeExpiry,
} from "../../utils/sessionTimeout";
import {
	showInvalidIpModal,
	setSimultaneousLogin,
} from "../../redux/modules/userInfo";
import AccessChecker from "./AccessChecker";
import failedIcon from "../../assets/icons/ic-failed.svg";

const TpaSelectPane = ({ allowSimultaneousLogin, setSimultaneousLogin }) => {
	const dispatch = useDispatch();
	const isShowModal = useSelector((state) => state.timeout.showModal);
	const [branches, setBranches] = useState([]);
	const [tpaids, setTpaids] = useState([]);
	const [selectedChannel, setSelectedChannel] = useState(null);
	const [selectedBranch, setSelectedBranch] = useState(null);
	const [selectedTPA, setSelectedTPA] = useState(null);
	const [isTerminalClosed, setIsTerminalClosed] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [accessDeniedMsg, setAccessDeniedMsg] = useState("");

	const [isResumeModalVisible, showResumeModal, hideResumeModal] =
		useToggle();
	const [isSessionModalVisible, showInSessionModal, hideInSessionModal] =
		useToggle();
	const [isTimerModalVisible, showTimerModal, hideTimerModal] = useToggle();
	const [isAccessDeniedShown, showAccessDenied, hideAccessDenied] =
		useToggle();
	const userInfo = UserInfoUtil.get();
	const { uuid, username, firstname, lastname } = userInfo;
	const history = useHistory();
	const isLoginApprovalEnabled =
		process.env.REACT_APP_ENABLE_LOGIN_APPROVAL == 1;

	const { data: existingRequest, error: existingRequestError } = useQuery(
		GET_LOGIN_REQUEST_USER,
		{
			variables: { uuid, username },
			skip: !isLoginApprovalEnabled,
		}
	);

	const isWaiting = isLoginApprovalEnabled
		? isTimerModalVisible || existingRequest
		: false;

	const channelId = selectedChannel;

	const tpaId =
		selectedTPA ||
		(isLoginApprovalEnabled &&
			existingRequest &&
			existingRequest.getLatestLoginRequestByUser.terminal.terminal_id);

	const tpaName =
		(selectedTPA &&
			tpaids.filter((tpa) => tpa.id == selectedTPA)[0].name) ||
		(isLoginApprovalEnabled &&
			existingRequest &&
			existingRequest.getLatestLoginRequestByUser.terminal.tpa_id);

	const branchId =
		selectedBranch ||
		(isLoginApprovalEnabled &&
			existingRequest &&
			(existingRequest.getLatestLoginRequestByUser.branch_id ||
				existingRequest.getLatestLoginRequestByUser.branch.branch_id));

	useEffect(() => {
		ApiClient.get(`/v3/user/get-operating-user-branches?userHash=${uuid}`)
			.then((resp) => {
				setBranches(resp.data.data);
			})
			.catch((error) => {
				console.error(error);
			});

		window.initializeShowSessionExpiry = (saved_time = {}) =>
			showSessionExpiry(
				() => dispatch(toggleModal()),
				() => dispatch(toggleUnlockReload()),
				() => dispatch(toggleUserLoggingOut()),
				saved_time
			);
		window.initializeRefreshTokenExpiry = () => refreshTokenBeforeExpiry();

		window.initializeRefreshTokenExpiry();
		window.initializeShowSessionExpiry();

		getScopes().then((data) => {
			dispatch(
				setSimultaneousLogin(checkSimultaneousLoginScope(data.data))
			);
		});
	}, []);

	useEffect(() => {
		ApiClient.get(
			`v3/branch-access/get-closing-time/${tpaId}?userHash=${uuid}`
		)
			.then((data) => {
				if (data.data.terminal_status === "CLOSED") {
					setIsTerminalClosed(true);
				} else {
					setIsTerminalClosed(false);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [tpaId]);

	existingRequestError &&
		console.log("Current Error: ", existingRequestError, {
			...existingRequestError,
		});

	const filterAndGetName = (array, id) => {
		const elem = array.find((x) => x.id === id);
		return elem ? elem.name : "";
	};

	const onChangeBranches = async (e) => {
		const branch = e.target.value;
		const branchItem = branches.find((e) => (e.id === branch));

		setSelectedChannel({
			is_api3_enabled: branchItem.is_api3_channel_enabled,
			is_enabled: branchItem.is_channel_enabled,
		});
		if (
			branchItem.is_api3_channel_enabled &&
			branchItem.is_channel_enabled
		) {
			setSelectedBranch(branch);
			setSelectedTPA("");
			setTpaids([]);

			console.log(branches.find((e) => e.id == branch));
			const response = await ApiClient.get(
				`/v3/user/get-operating-tpa-by-branch/${branch}?userHash=${uuid}`
			);
			setTpaids(response.data.data);
		}
	};

	const saveUserSession = async () => {
		setIsSubmitting(true);
		try {
			const { firstname, middlename, lastname, expires_in } = userInfo;
			const fullname = [firstname, middlename, lastname]
				.filter((n) => !!n)
				.join(" ");

			await ApiClient.post(
				`/v2/user/${uuid}/start-session`,
				JSON.stringify({
					branch_id: branchId,
					terminal_id: tpaId,
					user_name: username,
					fullname,
					expires_in,
				})
			);

			// save tpa to sessionStorage and reload
			TerminalUtil.set({
				id: tpaId,
				name: filterAndGetName(tpaids, tpaId),
			});
			//save branch to local storage
			const branch = branches.filter((e) => e.id == branchId);
			BranchUtil.set(branch);
			if (!allowSimultaneousLogin) {
				if (isTerminalClosed) {
					history.push("/cash-management");
				} else {
					history.push("/");
				}
			} else {
				history.push("/cash-management");
			}
			window.location.reload();
		} catch (error) {
			if (error.response.status === 422) {
				// show modal that tells the user that the tpa selected has active session
				showInSessionModal();
			} else if (error.response.status === 403) {
				dispatch(showInvalidIpModal());
			}
			console.log(error);
			setIsSubmitting(false);
		}
	};

	const onValidApproval = async (tpa_id) => {
		// checking
		if (tpa_id !== "") {
			const checkActive = await ApiClient.get(
				`/v2/terminals/${tpa_id}/check-session`
			)
				.then(({ status }) => {
					if (status === 200) {
						if (!allowSimultaneousLogin) {
							showResumeModal();
						} else {
							resumeOnClick();
						}
					}
				})
				.catch(
					({ response: { status } }) =>
						status === 422 && saveUserSession()
				);

			if (!checkActive) {
				console.error("Error");
			}
		}
	};

	const onClickSubmit = async () => {
		const isSelectedTpaValid = tpaids.filter(
			(terminal) => terminal.id === tpaId
		)[0]?.is_enabled;
		const isSelectedBranchValid = branches.filter(
			(branch) => branch.id === branchId
		)[0]?.is_enabled;

		const isBothInvalid = !isSelectedBranchValid && !isSelectedTpaValid;

		if (!isSelectedTpaValid || !isSelectedBranchValid) {
			setAccessDeniedMsg(
				`${isBothInvalid ? "These" : "This"} ${
					isSelectedBranchValid ? "" : "Branch"
				} ${isBothInvalid ? "and" : ""} ${
					isSelectedTpaValid ? "" : "TPAID"
				} ${isBothInvalid ? "are" : "is"}`
			);
			return showAccessDenied();
		}

		if (!isLoginApprovalEnabled) return onValidApproval(tpaId);
		else if (
			existingRequestError &&
			(existingRequestError.graphQLErrors[0].errorType ===
				"BFASupervisorLoginRequestException" ||
				existingRequestError.graphQLErrors[0].errorType ===
					"BFATemporaryLogoutRequestException")
		)
			return onValidApproval(tpaId);

		return showTimerModal();
	};

	const handleOnClose = () => {
		hideResumeModal();
		hideInSessionModal();
	};

	const resumeOnClick = () => {
		saveUserSession();
	};

	useEffect(() => {
		console.log(allowSimultaneousLogin);
	}, [allowSimultaneousLogin]);

	const clearChannel = useCallback(() => setSelectedChannel(null), []);
	const clearBranch = useCallback(() => setSelectedBranch(null), []);
	const clearTpa = useCallback(() => setSelectedTPA(null), []);

	return (
		<>
			<AccessChecker
				accessType="Channel"
				value={channelId}
				callback={clearChannel}
			/>
			<AccessChecker
				accessType="Branch"
				value={branchId}
				callback={clearBranch}
			/>
			<AccessChecker
				accessType="TPAID"
				value={tpaId}
				callback={clearTpa}
			/>
			{isShowModal && (
				<SessionTimeoutModal
					toggleModal={() => dispatch(toggleModal())}
					toggleUnlockReload={() => dispatch(toggleUnlockReload())}
				/>
			)}
			<TpaSelectModal
				title="TPA ID unavailable"
				show={isSessionModalVisible}
				content={
					<>
						<b>{filterAndGetName(tpaids, tpaId) || `This TPA `}</b>
						is already in use by another user. Please select another
						TPA ID.
					</>
				}
				handleOnClose={handleOnClose}
				center
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Button
						size="large"
						style={{
							border: "1px solid #CED4DA",
						}}
						onClick={handleOnClose}
						classes={{ label: styles.closeButtonLabel }}
					>
						Close
					</Button>
				</div>
			</TpaSelectModal>
			<TpaSelectModal
				handleOnClose={handleOnClose}
				title="TPA ID unavailable"
				show={isResumeModalVisible}
				content={
					<>
						<b>{filterAndGetName(tpaids, tpaId) || `This TPA`}</b>{" "}
						is already in session. Would you like to continue this
						session?
					</>
				}
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<Button
						size="large"
						style={{
							border: "1px solid #CED4DA",
						}}
						classes={{ label: styles.closeButtonLabel }}
						onClick={handleOnClose}
					>
						Cancel
					</Button>
					<Button
						size="large"
						style={{
							border: "1px solid #CED4DA",
							color: "#fff",
							background: "#32AA4D 0% 0% no-repeat padding-box",
							opacity: isSubmitting ? 0.75 : 1
						}}
						classes={{ label: styles.closeButtonLabel }}
						onClick={resumeOnClick}
						disabled={isSubmitting}
					>
						Confirm
						{isSubmitting && <CircularProgress size={15} style={{color: "#CED4DA", marginLeft: 2}} />}
					</Button>
				</div>
			</TpaSelectModal>
			<Grid item xs={12}>
				<div className={styles.tpaSelectMessage}>Welcome KaBayad!</div>
			</Grid>
			<Grid item xs={12} style={{ marginBottom: "27px" }}>
				<div className={styles.tpaSucessLoginMessage}>
					You have successfully logged-in
				</div>
			</Grid>

			<Grid item xs={12} style={{ padding: "0px 41px" }}>
				<label className={styles.formLabel}>
					Please select a branch
				</label>
				<FormControl
					variant="outlined"
					fullWidth
					style={{ marginTop: "13px" }}
				>
					<TextField
						select
						fullWidth
						label="Branch"
						variant="outlined"
						value={selectedBranch}
						onChange={onChangeBranches}
						classes={{ root: styles.select }}
						disabled={isWaiting}
					>
						{branches.map((branch, i) => (
							<MenuItem
								key={i}
								value={branch.id}
								classes={{
									gutters: styles.menuItemList,
									selected: styles.menuListSelected,
								}}
								ListItemClasses={{
									focusVisible: styles.menuItemFocusVisible,
								}}
							>
								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									{branch.name}
									{(!branch.is_enabled ||
										!branch.is_api3_enabled || !branch.is_api3_channel_enabled || !branch.is_channel_enabled) && (
										<span className={styles.disabled}>
											disabled
										</span>
									)}
								</div>
							</MenuItem>
						))}
					</TextField>
				</FormControl>
			</Grid>
			{selectedBranch && (
				<>
					<Grid
						item
						xs={12}
						style={{ padding: "0px 41px", marginTop: "16px" }}
					>
						<label className={styles.formLabel}>
							Select the TPA ID that you will be using
						</label>
						<FormControl
							variant="outlined"
							fullWidth
							style={{ marginTop: "10px" }}
						>
							<TextField
								select
								fullWidth
								label="TPAID"
								variant="outlined"
								value={selectedTPA}
								onChange={(e) => setSelectedTPA(e.target.value)}
								classes={{ root: styles.select }}
								disabled={isWaiting}
							>
								{tpaids.map((tpa, i) => (
									<MenuItem
										key={i}
										value={tpa.id}
										classes={{
											gutters: styles.menuItemList,
											selected: styles.menuListSelected,
										}}
										ListItemClasses={{
											focusVisible:
												styles.menuItemFocusVisible,
										}}
										disabled={
											tpa.in_session &&
											!allowSimultaneousLogin
										}
									>
										{
											<div
												style={{
													width: "100%",
													display: "flex",
													justifyContent:
														"space-between",
													alignItems: "center",
												}}
											>
												<span>{tpa.name}</span>
												{!!tpa.in_session && (
													<span
														className={
															styles.inSessionSpan
														}
													>
														in session
													</span>
												)}
												{(!tpa.is_enabled ||
													!tpa.is_api3_enabled) && (
													<span
														className={
															styles.disabled
														}
													>
														disabled
													</span>
												)}
											</div>
										}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ padding: "0px 41px", marginTop: "29px" }}
					>
						<Button
							style={{
								border: "1px solid #CED4DA",
								color: "#fff",
								background: isWaiting
									? "#AAA 0% 0% no-repeat padding-box"
									: "#32AA4D 0% 0% no-repeat padding-box",
								margin: "0px",
							}}
							onClick={onClickSubmit}
							disabled={isWaiting}
							fullWidth
						>
							Submit
						</Button>
					</Grid>
				</>
			)}
			{isLoginApprovalEnabled && isWaiting && (
				<TpaTimerModal
					branchId={branchId}
					tpaName={tpaName}
					tpaId={tpaId}
					uuid={uuid}
					username={username}
					firstname={firstname}
					lastname={lastname}
					existingRequest={existingRequest}
					onValidApproval={onValidApproval}
					showInvalidIpModal={() => dispatch(showInvalidIpModal())}
					hideTimerModal={hideTimerModal}
				/>
			)}

			<AccessDeniedDialog
				imgSrc={failedIcon}
				title="Access Denied!"
				message={
					<>
						{`${accessDeniedMsg} disabled in Bayad FA at the moment.
						Kindly contact your Admin for assistance.`}
					</>
				}
				open={isAccessDeniedShown}
				toggleDialog={hideAccessDenied}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		allowSimultaneousLogin: state.userInfo.allowSimultaneousLogin,
	}),
	{
		setSimultaneousLogin,
	}
)(TpaSelectPane);
